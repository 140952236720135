import React from 'react';
import styled from 'styled-components';
import withPageEntry from '../../withPageEntry';
import * as AppActions from '../../AppActions';
import * as JStorage from 'rev.sdk.js/Actions/JStorage';
import qs from 'query-string';
import Theme from '../../Theme';
import * as Ant from 'antd';
import {ArrowLeftShort} from '@styled-icons/bootstrap/ArrowLeftShort';

const collectionName = 'social_participation';
const route = '/info/social-participations/';

function InfoSocialParticipations(props) {
  const [fetchResp, setFetchResp] = React.useState([]);
  const [selectedDocument, setSelectedDocument] = React.useState(null);
  const {action, id} = qs.parse(props.location.search);
  const maxYear = new Date().getFullYear() + 1;
  const minYear = maxYear - 10;
  const [selectedYear, setSelectedYear] = React.useState(maxYear - 1);

  const years = [];
  let y = maxYear;
  while (y >= minYear) {
    years.push(y--);
  }

  const fetchDocumentList = React.useCallback(async () => {
    AppActions.setLoading(true);
    try {
      const extraQuery = {
        date: {
          $regex: selectedYear.toString(),
        },
      };

      const resp = await JStorage.fetchAllDocuments(
        collectionName,
        extraQuery,
        null,
      );

      setFetchResp(resp);
    } catch (ex) {
      console.warn(ex);
    }
    AppActions.setLoading(false);
  }, [selectedYear]);

  React.useEffect(() => {
    async function fetchData() {
      if (action === 'detail') {
        AppActions.setLoading(true);
        try {
          setSelectedDocument(
            await JStorage.fetchOneDocument(collectionName, {id}),
          );
        } catch (ex) {
          console.warn('REV_DBG', ex);
        }
        AppActions.setLoading(false);
      } else {
        setSelectedDocument(null);
        fetchDocumentList();
      }
    }

    fetchData();
  }, [fetchDocumentList, action, id]);

  if (action === 'detail') {
    return (
      <Wrapper>
        <a
          href={route}
          style={{display: 'flex', alignItems: 'center', marginBottom: 20}}
          onClick={(e) => {
            e.preventDefault();
            AppActions.navigate(route);
          }}>
          <ArrowLeftShort size={24} />
          <div style={{fontSize: 20}}>返回列表</div>
        </a>

        {selectedDocument && (
          <>
            <h1>{selectedDocument.name}</h1>
            <div style={{color: '#aaa'}}>{selectedDocument.date}</div>
            <div style={{marginBottom: 20}}>{selectedDocument.description}</div>
            <div className="twpaa-image-list">
              <Ant.Image.PreviewGroup
                preview={{
                  onChange: (current, prev) =>
                    console.log(
                      `current index: ${current}, prev index: ${prev}`,
                    ),
                }}>
                {(selectedDocument.images || []).map((it, idx) => {
                  return (
                    <Ant.Image
                      key={idx}
                      width={258}
                      height={176}
                      src={it.image}
                      style={{
                        borderRadius: 10,
                        overflow: 'hidden',
                        backgroundColor: '#ddd',
                        objectFit: 'cover',
                      }}
                    />
                  );
                })}
              </Ant.Image.PreviewGroup>
            </div>
          </>
        )}
      </Wrapper>
    );
  }

  return (
    <Wrapper>
      <h1 style={{fontSize: 28}}>社會參與</h1>

      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          flexWrap: 'wrap',
          marginBottom: 30,
          gap: 10,
        }}>
        <Ant.Select
          value={selectedYear}
          onChange={setSelectedYear}
          style={{width: 150}}>
          {years.map((y) => {
            return (
              <Ant.Select.Option value={y} key={y}>
                {y} 年度
              </Ant.Select.Option>
            );
          })}
        </Ant.Select>
      </div>

      <ul className="listview">
        {fetchResp.map((it) => {
          const firstImg =
            it.images?.length > 0
              ? it.images[0].image
              : '/images/site-logo.png';
          return (
            <li
              key={it.id}
              onClick={() => {
                AppActions.navigate(`${route}?action=detail&id=${it.id}`);
              }}>
              <img src={firstImg} alt="activity" />
              <div className="info">
                <div style={{color: '#aaa'}}>{it.date}</div>
                <div>{it.name}</div>
              </div>
            </li>
          );
        })}
      </ul>
    </Wrapper>
  );
}

const Wrapper = styled.div`
  h1 {
    text-align: center;
    padding-bottom: 15px;
    border-bottom: 4px solid ${Theme.colors.main};
    margin-bottom: 25px;
  }

  th.ant-table-cell {
    display: none;
  }

  ul.listview {
    padding: 0;
    list-style-type: none;
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
    grid-gap: 20px;

    & > li {
      border-radius: 8px;
      border: 1px solid #ccc;
      min-height: 200px;
      overflow: hidden;
      cursor: pointer;

      img {
        width: 100%;
        height: 120px;
        object-fit: cover;
        background-color: #eee;
      }
      .info {
        padding: 10px;
      }
    }
  }

  .twpaa-image-list {
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
  }
`;

export default withPageEntry(InfoSocialParticipations);
